var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cars-we-service col-12 col-md-10 col-xl-9 mx-auto mb-1" },
    [
      _vm.displayCityName
        ? _c(
            "h2",
            {
              staticClass: "mx-auto text-center mb-12",
              staticStyle: { "max-width": "533px" },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm
                      .$t("CarsWeServiceInDallas")
                      .replace("*City*", _vm.cityName)
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-none d-md-block" },
        [
          _c(
            "v-row",
            _vm._l(_vm.sixMakeColumns, function (item, index) {
              return _c("v-col", { key: index, staticClass: "col-2" }, [
                _c(
                  "ul",
                  _vm._l(item.makes, function (makeColumn, makeIndex) {
                    return _c(
                      "li",
                      { key: makeIndex, staticClass: "text-decoration-none" },
                      [
                        _c("span", { attrs: { rel: "canonical" } }, [
                          _vm._v(_vm._s(makeColumn)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-block d-md-none" },
        [
          _c(
            "v-row",
            _vm._l(
              _vm.threeMakeColumns,
              function (makeColumnItem, threeMakeColumnIndex) {
                return _c(
                  "v-col",
                  {
                    key: threeMakeColumnIndex,
                    staticClass: "col-4 d-flex justify-center",
                  },
                  [
                    _c(
                      "ul",
                      _vm._l(
                        makeColumnItem.makes,
                        function (threeMakeColumn, makeIdx) {
                          return _c(
                            "li",
                            {
                              key: makeIdx,
                              staticClass: "text-decoration-none",
                            },
                            [
                              _c("span", { attrs: { rel: "canonical" } }, [
                                _vm._v(_vm._s(threeMakeColumn)),
                              ]),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]
                )
              }
            ),
            1
          ),
        ],
        1
      ),
      !_vm.displayCityName
        ? _c(
            "v-layout",
            { staticClass: "mx-auto mb-5 justify-center mt-10" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-none white--text font-weight-bold",
                  staticStyle: { "font-family": "Rubik" },
                  attrs: {
                    elevation: "0",
                    height: "48",
                    width: "334",
                    color: "rgb(18, 148, 95)",
                    to: { name: "AutoEstimate" },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("GetAnEstimate")) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticStyle: { "margin-bottom": "100px" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }