var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "cities-we-serve" } },
    [
      _c(
        "v-row",
        { staticClass: "col-12" },
        [
          _c("v-col", { staticClass: "col-md-6 mx-auto" }, [
            _c("div", { staticClass: "mb-5" }, [
              _c(
                "p",
                {
                  staticClass: "mb-1 information header-title text-center",
                  attrs: { id: "cities-we-serve-title" },
                },
                [
                  _vm.subtitleAboveTitle
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$t("CitiesWeServe.Title"))),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "h2",
                { staticClass: "title-section  mb-1 mt-0 text-center" },
                [_vm._v(" " + _vm._s(_vm.$t("CitiesWeServe.SubTitle")) + " ")]
              ),
              !_vm.subtitleAboveTitle
                ? _c(
                    "p",
                    {
                      staticClass: "mb-1 information header-title text-center",
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("GrowingAndWorkingToExpand")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-none d-lg-block" },
        [
          _c(
            "v-row",
            { staticClass: "justify-center" },
            [
              _c(
                "v-col",
                { staticClass: "col-md-6 py-1" },
                _vm._l(_vm.cities, function (city, index) {
                  return _c(
                    "v-btn",
                    {
                      key: index,
                      staticClass: "mx-2 my-2 text-capitalize city-btn",
                      attrs: {
                        color: "#00663D",
                        to: { name: city.goToName },
                        outlined: "",
                      },
                    },
                    [_vm._v(" " + _vm._s(city.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-block d-lg-none" },
        [
          _c(
            "v-row",
            { staticClass: "justify-center col-12" },
            [
              _c(
                "v-col",
                { staticClass: "col-md-7 py-1 px-4" },
                _vm._l(_vm.cities, function (city, index) {
                  return _c(
                    "v-btn",
                    {
                      key: index,
                      staticClass: "mx-2 my-2 text-capitalize city-btn",
                      attrs: {
                        small: "",
                        color: "#00663D",
                        to: { name: city.goToName },
                        outlined: "",
                      },
                    },
                    [_vm._v(" " + _vm._s(city.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("zip-code-checker-component"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }